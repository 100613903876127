.rc-time-picker {
  width: 170px;
}

.rc-time-picker input {
  width: 100%;
}

.rc-time-picker-panel * {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #666666;
}

.rc-time-picker-panel-inner {
  border-radius: 0;
}
